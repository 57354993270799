import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import iconF1 from "../../assets/image/svg/feature8-icon1.svg";
import iconF2 from "../../assets/image/made_swiss.svg";
import iconF3 from "../../assets/image/inner/icon-doller-paste.svg";

const Features = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Features Area --> */}
      <div className="feature-section pt-0 pt-lg-21 pb-7 bg-default-6">
        <Container>
          {/* <Row className="justify-content-center">
            <Col xl="5" lg="6" md="8">
              <div className="section-title text-center mb-13 mb-lg-21">
                <h2 className="title gr-text-4 mb-6 heading-color">{t('content2_title')}</h2>
                <p className="gr-text-9 mb-0 text-color-opacity">
                  {t('content2_body')}
                </p>
              </div>
            </Col>
          </Row> */}
          <Row className="align-items-center justify-content-center">
            <Col
              lg="4"
              md="6"
              className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-8 px-xl-8 "
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="feature-widget text-center">
                <div className="widget-icon square-80 rounded-15 mx-auto mb-9 mb-lg-12 bg-blue shadow-blue">
                  <i className="fas fa-robot mx-1 text-white gr-text-3"></i>
                </div>
                <div className="widget-text">
                  <h3 className="title heading-color gr-text-6 mb-7">{t('item11_title')}</h3>
                  <p className="gr-text-11 text-color-opacity mb-0">
                    {t('item11_body')}
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-8 px-xl-8"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <div className="feature-widget text-center">
                <div className="widget-icon square-80 rounded-15 mx-auto mb-9 mb-lg-12 shadow-red" style={{backgroundColor:'#f00'}}>
                  <img src={iconF2} alt="" />
                </div>
                <div className="widget-text">
                  <h3 className="title heading-color gr-text-6 mb-7">{t('item12_title')}</h3>
                  <p className="gr-text-11 text-color-opacity mb-0">
                    {t('item12_body')}
                  </p>
                </div>
              </div>
            </Col>
            <Col
              lg="4"
              md="6"
              className="mb-11 mb-lg-19 px-xs-6 px-md-6 px-lg-8 px-xl-8"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="600"
            >
              <div className="feature-widget text-center">
                <div className="widget-icon square-80 rounded-15 mx-auto mb-9 mb-lg-12 bg-green shadow-white">
                  {/* <img src={iconF3} alt="" /> */}
                  <i className="fas fa-dollar-sign mx-1 text-white gr-text-3"></i>

                </div>
                <div className="widget-text">
                  <h3 className="title heading-color gr-text-6 mb-7">{t('item13_title')}</h3>
                  <p className="gr-text-11 text-color-opacity mb-0">
                    {t('item13_body')}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Features;
