import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import ReactPlayer from "react-player";
import { Container, Row, Col } from "react-bootstrap";

// const youTubeVideoHowItWorks =   'https://www.youtube.com/watch?v=07ol_k9UVFw' // How to apply an anti-snoring
const youTubeVideoBiteRotating = 'https://www.youtube.com/watch?v=U3ZYsLjT0GI' // Rotating Bite - Maßgefertigte Schnarch-Schiene

  // @media ${device.md} {
  //   width: 70%;
  //   height: 650px;
  // }

// const DivStyled = styled.div`
//   margin: 0 auto;
//   width: 90%;
//   height: 400px;
//   opacity: 0;
//   visibility: hidden;
//   transition: all 2s ease-out;
//   &.loaded {
//     opacity: 1;
//     visibility: visible;
//   }
// `;


const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section2 pt-12 pb-9 pt-lg-21 pb-lg-25 bg-default-2">
        <Container>
          <Row className="justify-content-center">
            <Col xl="6" lg="8" sm="10">
              <div className="section-title text-center mb-12 mb-lg-17">
                <h2 className="title gr-text-4 mb-7 heading-color">
                  {t('content3_title')}
                </h2>
                <p className="gr-text-8 px-lg-7 px-xl-0 text-color-opacity">
                  {t('content3_body')}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="6" className="p-relative">
              <div className="content-video-thumb rounded-8">
                <div className={`h-100 d-flex align-items-center w-100`}>
                  {/* <DivStyled 
                  // className={`${loading ? "loading" : "loaded"}`}
                  >  */}
                    <ReactPlayer
                    url={youTubeVideoBiteRotating}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 0, controls: 1, rel: 0, modestbranding: 1 }
                      }
                    }}
                      // width="100%"
                      // height="100%"
                      // controls
                      // onReady={() => {
                      //   setLoading(false);
                      // }}
                    />
                  {/* </DivStyled> */}
                </div>
              </div>
            </Col>
            <Col lg="5">
              <div className="content-widget mt-7 mt-lg-0 ps-xl-13">
                <Row className="align-items-center">
                  <Col
                    md="6"
                    lg="12"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget my-7 media">
                      <div className="media-icon circle-sm gr-bg-blue-opacity-1 me-8">
                        <span className="count text-primary--light-only gr-text-9 heading-color">
                          1
                        </span>
                      </div>
                      <div className="media-body">
                        <h3 className="w-title mb-5 gr-text-7 heading-color">{t('item1_title')}</h3>
                        <p className="gr-text-9 mb-0 text-color-opacity">
                          {t('item1_body')}{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="12"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="single-widget my-7 media">
                      <div className="media-icon circle-sm gr-bg-blue-opacity-1 me-8">
                        <span className="count text-primary--light-only gr-text-9 heading-color">
                          2
                        </span>
                      </div>
                      <div className="media-body">
                        <h3 className="w-title mb-5 gr-text-7 heading-color">
                          {t('item2_title')}
                        </h3>
                        <p className="gr-text-9 mb-0 text-color-opacity">
                          {t('item2_body')}{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="12"
                    data-aos="fade-left"
                    data-aos-duration="1800"
                  >
                    <div className="single-widget my-7 media">
                      <div className="media-icon circle-sm gr-bg-blue-opacity-1 me-8">
                        <span className="count text-primary--light-only gr-text-9 heading-color">
                          3
                        </span>
                      </div>
                      <div className="media-body">
                        <h3 className="w-title mb-5 gr-text-7 heading-color">
                          {t('item3_title')}
                        </h3>
                        <p className="gr-text-9 mb-0 text-color-opacity">
                          {t('item3_body')}{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  {/* <Col
                    md="6"
                    lg="12"
                    data-aos="fade-left"
                    data-aos-duration="1800"
                  >
                    <div className="single-widget my-7 media">
                      <div className="media-icon circle-sm gr-bg-blue-opacity-1 me-8">
                        <span className="count text-primary--light-only gr-text-9 heading-color">
                          4
                        </span>
                      </div>
                      <div className="media-body">
                        <h3 className="w-title mb-5 gr-text-7 heading-color">
                          {t('item4_title')}
                        </h3>
                        <p className="gr-text-9 mb-0 text-color-opacity">
                          {t('item4_body')}{" "}
                        </p>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-10">
            <Col md="9" lg="7" xl="6">
              <div className="text-center dark-mode-texts">
                <h2 className="gr-text-4 mb-7 text-black">{t('cta_title')}</h2>
                <p className="gr-text-9 mb-11 px-xl-7">
                  {t('cta_body')}
                </p>

                <Link
                  to="/contact"
                  className="btn btn-blue gr-hover-y px-lg-9"
                >
                  {t('cta_cta')}
                </Link>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
};

export default Content;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

