// src/components/NewsList.js
import React from 'react';
import NewsItem from './newsitem';
import './newsitem.css'; // Assicurati che questo file esista ed abbia degli stili appropriati
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

const NewsList = ({ news }) => {
  const { t } = useTranslation();

  return (
    <div className="content-section2 pt-12 pb-qw pt-lg-16 pb-lg-16 bg-default-2">
      <Container>
        <Row className="justify-content-center">
          <Col xl="12" lg="12" sm="12">
            <div className="section-title text-center mb-12 mb-lg-17">
              <h2 className="title gr-text-4 mb-7 heading-color">
                {t('newslist_title')}
              </h2>

              <div className="news-list">
                {news.map((item, index) => (
                  <NewsItem key={index} title={item.title} imageUrl={item.imageUrl} publishDate={item.publishDate} newsUrl={item.newsUrl}/>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsList;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

