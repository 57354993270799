import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import { Container, Row, Col } from "react-bootstrap";

import imgC from "../../assets/image/snorefreeswiss/bite3d.jpg";
// import imgCS1 from "../../assets/image/telemedicine/png/content2-img-sub-1.png";
// import imgCS from "../../assets/image/telemedicine/png/content2-img-sub.png";

const Content2 = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Content2 Area --> */}
      <div className="content-section pt-11 pt-lg-22 pb-13 pb-lg-26 bg-default-6 hover-shadow-up">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col
              sm="10"
              md="7"
              lg="6"
              xl="5"
              className="offset-xl-2 order-lg-2 mb-9 mb-lg-0"
            >
              <div
                className="double-image-group"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <img
                  className="main-img w-100 rounded-8"
                  src={imgC}
                  alt=""
                  width={400}
                  // style={{borderRadius:20}}
                />
                {/* <div
                  className="abs-image-1 gr-z-index-n1"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                >
                  <img
                    src={imgCS1}
                    alt=""
                    className="h-sm-100  anim-shadow-up rounded-10"
                  />
                </div> */}
                {/* <div
                  className="abs-image-2"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                  css={`
                    z-index: initial !important;
                  `}
                >
                  <img src={imgCS} alt="" />
                </div> */}
              </div>
            </Col>
            <Col sm="10" md="9" lg="6" xl="5" className="order-lg-1">
              <div className="content-text">
                <h2 className="gr-text-4 mb-8 heading-color">
                  {t('content2_title')}
                </h2>
                <p className="gr-text-9 mb-0 pe-lg-10 text-color-opacity">
                  {t('content2_body')}
                  {" "}
                </p>
                {/* <Link
                  to="/contact"
                  className="btn-link with-icon gr-text-blue gr-text-9 fw-bold mt-9"
                >
                  {t('content2_cta')}<i className="fas fa-arrow-right"></i>
                </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content2;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
