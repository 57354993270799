// src/components/NewsItem.js
import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import './newsitem.css'; // Assicurati che questo file esista ed abbia degli stili appropriati

const NewsItem = ({ title, imageUrl, publishDate, newsUrl }) => {
  const { t } = useTranslation();

  const date = new Date(publishDate);
  const displayDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;

  return (
    <Link to={newsUrl} className="">
            <div className="news-item">
        <img src={imageUrl} alt={t({title})} className="news-image" />
        <div className="news-title-container">
          <div className="news-title">{t(title)}</div>
          <div className="news-date">{displayDate}</div>        
        </div>
      </div>
    </Link>
  );
};

export default NewsItem;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

